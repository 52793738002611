<script setup lang="ts">
import type { BlokHero } from '~~/types/storyblok/bloks'
const props = defineProps<{
  blok: BlokHero
  isMain?: boolean
  title?: string
}>()

const horizontalAlignment = computed(() => {
  switch (props.blok?.horizontal_alignment) {
    case 'center':
      return 'text-center'
    case 'right':
      return 'text-right'
    default:
      return 'text-left'
  }
})
const verticalAlignment = computed(() => {
  switch (props.blok?.vertical_alignment) {
    case 'end':
      return 'items-end'
    case 'center':
      return 'items-center'
    default:
      return 'items-start'
  }
})

const useSmallHero = computed(() => props.blok?.size === 'sm')
const useFullHero = computed(() => props.blok?.size === 'full')

const hasMedia = computed(() => !!props.blok?.media?.length)

const sectionTheme = computed(() => {
  return props.blok?.theme ? props.blok.theme : 'theme-grey-100'
})
</script>

<template lang="pug">
section(
  v-if="blok"
  v-editable="blok"
  :class="[sectionTheme]"
  class="blok-hero flex flex-col md:flex-row relative overflow-hidden border-b border-grey-250 md:border-none"
)
  div(
    class="flex w-full h-full"
    :class="[verticalAlignment, useFullHero ? 'pt-[100%] md:pt-0 md:min-h-screen' : useSmallHero ? 'md:pt-[12.5vh] md:pb-[7.5vh]' : 'md:pt-[17.5vh] md:pb-[12.5vh]', hasMedia ? 'md:z-20 bg-grey-100 text-blue-dark md:bg-transparent md:text-current' : '']"
  )
    div(
      class="container pb-36 pt-120 px-18 md:py-36"
      :class="useFullHero ? 'pt-36 md:pt-180 lg:pt-220 lg:pb-72' : ''"
    )
      div(class="md:grid md:grid-cols-12")
        div(class="md:col-span-10 md:col-start-2" :class="horizontalAlignment")
          div(
            v-if="title || blok.headline"
            class="lg:max-w-prose-wide"
            :class="horizontalAlignment === 'text-center' ? 'm-auto' : ''"
          )
            component(
              v-if="blok.headline || title"
              :is="isMain ? 'h1' : 'h2'"
              class="heading-1"
            ) {{ blok.headline || title }}
            component(
              v-if="blok.subheadline"
              :is="isMain ? 'h2' : 'h3'"
              class="heading-4 font-normal md:font-bold lg:max-w-prose-tight"
            ) {{ blok.subheadline }}

          UiButton(
            v-if="props.blok?.cta"
            v-for="cta in props.blok.cta"
            :blok="cta"
            :class="props.blok?.horizontal_alignment === 'text-center' ? 'self-center' : 'self-start'"
          )

  div(
    v-if="hasMedia"
    class="media -order-1 md:order-1 absolute w-full md:h-full pointer-events-none"
  )
    StoryblokComponent(
      v-for="item in props.blok?.media"
      :key="item._uid"
      :blok="item"
      :object-cover="true"
      :with-overlay="props?.blok?.use_image_effect"
      :overlay-from-bottom="props?.blok?.image_effect_from_bottom"
      :width="1920"
      :height="1080"
      :is-hero="true"
    )
</template>
